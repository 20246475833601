.todo__title {
    font-size: 24px;
    line-height: 33px;
    font-weight: 700;
    margin: 0;
    color: var(--color-text);
}

.todo__list {
    margin-bottom: 25px;
}

.todo__text {
    position: relative;
    font-size: 16px;
    line-height: 33px;
    margin: 0;
    padding-left: 20px;
    color: var(--color-text);
}

.todo__text::before {
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    top: 40%;
    left: 0;
    border-radius: 100%;
    background: #B7280F
}

.todo__new {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 370px;
    font-size: 16px;
    line-height: 17px;
    font-weight: 300;
    border-bottom: 1px solid #E4E4E4;
    padding: 20px 10px;
}

.todo__new:first-child {
    border-top: 1px solid #E4E4E4;
}

.todo__num {
    position: relative;
    margin-right: 20px;
    color: var(--color-text);
}

.todo__num::before {
    content: '';
    position: absolute;
    height: 25px;
    width: 25px;
    top: 50%;
    left: 50%;
    border-radius: 100%;
    border: 1px solid #C4C4C4;
    transform: translate(-50%, -50%);
}

.todo__text-list {
    color: var(--color-text);
}

.input__change {
    width: 200px;
    height: 10px;
    padding: 10px;
    margin: 0;
}

.all__time {
    margin-top: 20px;
    font-size: 16px;
    line-height: 17px;
    font-weight: 300;
    color: #999999;
}

.todo__content {
    display: flex;
    gap: 16px;
}

.todo__main {
    width: 40%;
}