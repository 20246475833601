.choices__inner {
    max-width: 400px;
    background: #F4F4F4 !important;
    font-size: 16px !important;
    border: none !important;
    padding: 19px 15px !important;
}

.choices {
    width: 100%;
    max-width: 400px;
}

.choices__list--dropdown.is-active {
    max-width: 400px;
    background: #F4F4F4 !important;
    border: none !important;
}

.choices.is-open {
    z-index: 50;
}

.choices__list--dropdown .choices__item {
    position: relative;
    font-size: 16px !important;
    border: none !important;
    padding: 19px 15px !important;
}

.is-selected {
    display: none !important;
}

.choices[data-type*=select-one]:after {
    content: '';
    display: inline-block !important;
    padding: 3px !important;
    height: 3px !important;
    width: 3px !important;
    border: solid #B7280F !important;
    border-width: 0 1px 1px 0 !important;
    right: 14.5px !important;
    top: 37% !important;
    margin-top: -3.5px !important;
    pointer-events: none !important;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg) !important;
    transition: all 0.3s ease 0s !important;
}

.choices[data-type*=select-one].is-open:after {
    border: solid rgb(255, 255, 255) important;
    border-width: 0 1px 1px 0 !important;
    display: inline-block !important;
    height: 3px !important;
    width: 3px !important;
    border-color: #B7280F !important;
    padding: 3px !important;
    margin-top: -7.5px !important;
    right: 14.5px !important;
    top: 61% !important;
    transform: rotate(-135deg) !important;
    -webkit-transform: rotate(-135deg) !important;
}

.statistics__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.statisctics__title {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-text);
}

.statistics__pimidoro {
    display: flex;
    gap: 32px;
    margin-bottom: 32px;
}

.pimidoro__content {
    background: var(--bg-pomidoro);
}

.pomidoro__small-block {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.pomidoro-todo {
    width: 296px;
    padding: 25px;
    height: 100%;
}

.pomidoro-comlete {
    width: 296px;
    height: 179px;
}

.pomidoro-large {
    width: 100%;
}

.pomidoro__day {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-text);
}

.pomidoro__day-text {
    color: var(--color-text);
}

#myChart {
    height: 471px !important;
    width: 100% !important;
}

.pomidoro__time {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
    color: var(--color-text);
}

.pomidoro__all-time {
    font-weight: 700;
    color: #DC3E22;
    padding-left: 5px;
}

.pomidoro__not {
    text-align: center;
    padding: 24px;
}

.pomidoro__img {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    font-size: 24px;
    font-weight: 700;
    color: #999999;
    padding: 24px;
}

.pomidoro__done-text {
    font-size: 24px;
    font-weight: 700;
    color: #fff;
    background: #DC3E22;
    padding: 10px;
    text-align: center;
}

.statistics__pimidoro-bottom {
    display: flex;
    gap: 32px;
    width: 100%;
}

.statisctics__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px;
    width: 100%;
}

.statistics-focus {
    background: var(--bg-timer);
    filter: grayscale(100%);
}

.statistics-focus-done {
    background: #FFDDA9;
}

.statistics-pause-done {
    background: #DFDCFE;
}

.statistics-stop-done {
    background: #C5F1FF;
}

.statisctics__info-title {
    font-size: 24px;
    font-weight: 700;
    color: var(--color-text);
}

.statisctics__info-text {
    font-size: 64px;
    color: var(--color-text);
}