@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
    padding: 0;
    margin: 0;
    background-color: var(--bg-color);
    font-size: 14px;
    line-height: 16px;
    font-family: 'Roboto', serif;
}

* {
    color: #333;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
}

.layout {
    max-width: 1470px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}