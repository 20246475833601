.modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.737);
    z-index: 50;
}

.modal__content {
    position: absolute;
    top: 30%;
    left: 50%;
    display: flex;
    transform: translate(-50%, -30%);
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 25px;
    width: 400px;
    background: rgb(255, 255, 255);
    box-shadow: 2px 6px 53px -18px rgb(135, 135, 135);
}

.settings-content {
    border-radius: 8px;
    padding: 0;
}

.settings__btn-group {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-bottom: 15px;
}

.modal__title {
    font-size: 24px;
    line-height: 17px;
    font-weight: 400;
    margin-bottom: 25px;
}

.modal__delete {
    width: 150px;
    padding: 19px;
    font-size: 16px;
    line-height: 17px;
    font-weight: 500;
    color: #fff;
    background: #DC3E22;
    margin: 0 auto;
    margin-bottom: 10px;
}

.modal__cancel {
    font-size: 16px;
    line-height: 17px;
    font-weight: 300;
    text-decoration: underline;
}

.modal__close {
    position: absolute;
    top: 10%;
    right: 5%;
}

.settings__decrement {
    position: relative;
}

.settings__decrement::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    height: 3px;
    width: 15px;
    transform: translate(-50%, -50%);
}

.settings__time {
    font-size: 20px;
    font-weight: 600;
}

.setting__text {
    font-size: 16px;
    font-weight: 500;
}

.modal__done {
    padding: 15px 5px;
    font-size: 16px;
    background: #A8B64F;
    color: #fff;
}

.notification__off {
    background: #c1c1c1;
    text-align: left;
}

.notification__on {
    background: #A8B64F;
    text-align: right;
}

.notification__btn {
    width: 110px;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    margin: 0 auto;
    border-radius: 5px;
    transition: all 0.3s ease 0s;
}